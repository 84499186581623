.get-lucky {
    .text {
        font-weight: bold;
    }
    
    .description {
        margin-left: 5px;
        font-size: 0.85em;
    }

    .sub-text {
        color: rgb(140, 140, 140);
        font-size: 14px;
        font-weight: normal;
        line-height: 20px;

        .info-circle {
            margin-right: 4px;
        }
    }

    .selector {
        margin-bottom: 4px;
        max-width: 440px;
    }
}
