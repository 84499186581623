@import 'src/styles/commons';

.hotel-links {
  .ant-row.ant-form-item-row {
    margin-bottom: 0;
  }

  .input-row {
    margin-bottom: 24px;

    &-no-rules {
      .ant-form-item-explain {
        display: none;
      }
    }

    .ant-row.ant-form-item-row {
      margin-bottom: 0;
    }

    &:last-child {
      margin-bottom: 0px;
    }

    .ant-row.ant-form-item-row {
      margin-bottom: 0;

      .ant-select {
        width: 320px;
        border-color: rgba(0, 0, 0, 0.15);
      }

      .ant-input {
        width: 440px;
        border-color: rgba(0, 0, 0, 0.15);
      }

      .ant-input-number {
        width: 104px;
        border-color: rgba(0, 0, 0, 0.15);
      }

      .ant-form-item-label {
        margin-right: 16px;
        width: 192px;
        max-width: unset;

        label {
          font-weight: 500;
          font-size: 14px;
        }

        label.ant-form-item-required::before {
          display: none;
        }

        label::after {
          display: none;
        }
      }

      .view-text {
        line-height: 32px;

        &-empty {
          color: rgb(191, 191, 191);
        }
      }
    }
  }

  .description {
    margin-left: 208px;
    margin-top: 4px;
    display: flex;
    flex-direction: row;
    vertical-align: middle;
    color: #8c8c8c;
    font-size: 14px;
    line-height: 20px;

    .anticon {
      margin: 3px 5px 3px 0;
    }
  }
}
