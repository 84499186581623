@import 'src/styles/commons';

.quickFilterContent {
    background-color: $grey100;
}

.quickFilterTabs .ant-tabs-nav {
    background-color: white;
    padding: 0px 24px 0px 24px;
    margin: 0px;
}