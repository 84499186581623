@import 'src/styles/commons';

.general-form {
  height: 100%;
}

.input-value {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  .combine-input {
    display: flex;
  }

  .combine-input .ant-form-item,
  .input-value .ant-form-item {
    margin-bottom: 0;
  }

  .combine-input
    .ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    border-left: 0;
  }

  .combine-input .ant-select-disabled .ant-select-arrow {
    display: none;
  }
}

.disclaimer {
  margin-top: 12px;
  color: #8c8c8c;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}
