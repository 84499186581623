.bookable-days-picker__days {
    display: flex;
    flex-wrap: wrap;
    margin-top: 16px;
    max-width: 600px;

    .ant-tag {
        margin-bottom: 8px;
    }
}
