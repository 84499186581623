@import 'src/styles/commons';

.sticky-header {
    top: 0;
    background-color: white;
    position: sticky;
    z-index: 1;
    padding: 16px;
    margin-top: 24px;
    border: 1px solid #E0E0E0;
    border-radius: 4px;
    margin-bottom: 4px;
}

.left-right-header__left {
    .extranet-inventory-header-previous {
        margin-left: 0px;
        margin-right: 8px;
        width: 32px;
    }

    .extranet-inventory-header-next {
        margin-left: 8px;
        width: 32px;
    }
    
    .extranet-inventory-header-errors-button{
        margin-left: 16px;

    }
}

.extranet-confirmation-popin {
    p {
        display: inline;
        line-height: 22px;
        color: #101010;
        font-size: 14px;
    }

    .popin-text {
        width: 260px;
        height: 66px;

        .grey-text {
            color: #8C8C8C;
        }
    }

    .popin-cta {
        margin-top: 12px;
        text-align: right;
        .popin-no {
            margin-right: 8px;
            height: 24px;
        }

        .popin-yes {
            height: 24px;
        }
    }
}
