@import 'src/styles/commons';


.sortable-row {
    &[style*='position: fixed'] {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid $grey300;
        border-radius: 4px;
        background: #FFF;
        padding: 16px;
        width: 100%;
        max-width: 1200px;
    }
}
