@import 'src/styles/commons';


.sortable-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #FFF;
    padding: 12px 20px 12px 35px;
    width: 100%;
    height: 88px;

    &--bordered {
        border: 1px solid $grey300;
        border-radius: 4px;
    }

    &:first-child {
        border-radius: 4px 4px 0 0;
    }

    &:last-child {
        border-radius: 0 0 4px 4px;
    }

    &[style*='position: fixed'] {
        border: 1px solid $grey300;
        border-radius: 4px;
    }

    .anticon {
        cursor: pointer;
        color: $grey500;
        font-size: 18px;
    }
}

.sortable-item__name {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px 0 30px;

    .ant-row {
        &.ant-form-item {
            margin-bottom: 0; // TODO: bof bof because of FormGroup
        }
    }

    .sortable-item__input {
        flex-grow: 1;
        margin-right: 50px;
    }
}

.sortable-item__infos {
    display: flex;
    flex-grow: 1;
    align-items: center;
}

.sortable-item__thumbnail {
    width: 64px;
    height: 64px;

    img {
        border-radius: 4px;
        width: 64px;
        height: 64px;
        object-fit: cover;
    }
}

.sortable-item__actions {
    display: flex;
    align-items: center;

    .anticon {
        padding: 0 8px;
    }
}
