@import 'src/styles/commons';


.sale-info {
    display: flex;
    width: 100%;
}

.sale-info__timeline {
    flex-grow: 1;
    margin-right: 24px;
}

.sale-info__extra-days {
    flex-basis: 350px;
    flex-grow: 0;
    flex-shrink: 0;
}

.sale-info__progress {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: -8px;
    margin-bottom: 8px;

    .ant-progress {
        margin-right: 8px;
        margin-bottom: 1px;
    }
}

.sale-info__labels {
    display: flex;
    justify-content: space-between;
    font-weight: 500;

    p {
        margin-bottom: 8px;
    }
}

.sale-info__dates {
    display: flex;
    justify-content: space-between;
}

.sale-info__section {
    margin-top: 24px;
}

.sale-info__bookable-days {
    margin-top: 16px;

    .ant-tag {
        margin-bottom: 8px;
    }
}

.sale-info__extra-day {
    border-top: 1px solid $grey300;
    padding: 16px 0;

    &:last-child {
        border-bottom: 1px solid $grey300;
    }
}
