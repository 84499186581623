@import 'src/styles/commons';


.actions-menu {
    display: flex;
    justify-content: space-around;
    font-size: 16px;

    .anticon {
        padding: 4px;
        color: $grey500;
    }
}
