.explanation_multiRate_information {
  font-size: 12px;
  color: #8c8c8c;
  font-weight: 400;
  margin-top: 8px;
}

.explanation_multiRate_information .anticon {
  margin-right: 8px;
}
