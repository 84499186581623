@import 'src/styles/commons';

.extranet-inventory {
  margin-left: 200px;
  width: 100%;
  .header {
    display: flex;
    align-items: center;

    .dedge-header {
      color: rgb(64, 45, 114);
      font-size: 16px;
      font-weight: 500;
      line-height: 18px;
      margin-right: 8px;
      display: flex;
      align-items: center;

      .dedge-header__thumbnail {
        align-items: center;
        justify-content: center;
        display: flex;
        width: 16px;
        height: 16px;
        overflow: hidden;
        margin-right: 8px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .siteminder-header {
      color: rgb(75, 105, 240);
      font-size: 16px;
      font-weight: 500;
      line-height: 18px;
      margin-right: 8px;
      display: flex;
      align-items: center;

      .siteminder-header__thumbnail {
        align-items: center;
        justify-content: center;
        display: flex;
        width: 14px;
        height: 14px;
        overflow: hidden;
        margin-right: 8px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .reservit-header {
      color: rgb(0, 0, 0);
      font-size: 16px;
      font-weight: 500;
      line-height: 18px;
      margin-right: 8px;
      display: flex;
      align-items: center;

      .reservit-header__thumbnail {
        align-items: center;
        justify-content: center;
        display: flex;
        width: 14px;
        height: 14px;
        overflow: hidden;
        margin-right: 8px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .cubilis-header {
      color: rgb(0, 0, 0);
      font-size: 16px;
      font-weight: 500;
      line-height: 18px;
      margin-right: 8px;
      display: flex;
      align-items: center;

      .cubilis-header__thumbnail {
        align-items: center;
        justify-content: center;
        display: flex;
        width: 14px;
        height: 14px;
        overflow: hidden;
        margin-right: 8px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    
    .travelclick-header {
      color: rgb(0, 0, 0);
      font-size: 16px;
      font-weight: 500;
      line-height: 18px;
      margin-right: 8px;
      display: flex;
      align-items: center;

      .travelclick-header__thumbnail {
        align-items: center;
        justify-content: center;
        display: flex;
        width: 14px;
        height: 14px;
        overflow: hidden;
        margin-right: 8px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .roomcloud-header {
      color: rgb(0, 0, 0);
      font-size: 16px;
      font-weight: 500;
      line-height: 18px;
      margin-right: 8px;
      display: flex;
      align-items: center;

      .roomcloud-header__thumbnail {
        align-items: center;
        justify-content: center;
        display: flex;
        width: 24px;
        height: 14px;
        overflow: hidden;
        margin-right: 8px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .header__updated {
      color: rgb(140, 140, 140);
      font-size: 16px;
      font-weight: normal;
      line-height: 16px;
    }
  }

  .container {
    overflow-x: auto;
    position: relative;
    padding: 0 24px 24px 24px;
    max-width: 1440px;
    background-color: #fff;
  }

  .ant-table {
    min-width: 820px;
    overflow: hidden;
  }

  &__row {
    padding-left: 0;
    height: 48px;
    line-height: 48px;
    font-size: 16px;
    font-weight: 500;
  }
  .rowTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .extranet-inventory__row {
      padding-left: 0;
      height: 48px;
      line-height: 48px;
      font-size: 16px;
      font-weight: 500;
    }
  }

  &__table-row {
    td {
      &.ant-table-cell {
        padding: 0;
      }

      &.ant-table-row-expand-icon-cell {
        padding: 0 16px 0 8px;

        .ant-table-row-expand-icon {
          border-color: #d9d9d9;
        }
      }
    }
  }

  .ant-table-title {
    border-bottom: solid 1px $grey200;
  }

  .ant-table-thead {
    display: none;
  }

  .ant-table-tbody {
    tr:last-child {
      .extranet-inventory__grid {
        .ant-row:last-child {
          .ant-col {
            border-bottom: none;
          }
        }
      }
    }
  }

  .ant-table-expanded-row td {
    padding: 0;
  }

  .ant-table-expanded-row-level-1 {
    td {
      border-bottom: none;
    }
  }

  .ant-picker {
    width: auto;

    &-disabled {
      opacity: 1;
      background-color: #fff;
      color: #000;
    }

    &-input {
      width: auto;

      input {
        &[disabled] {
          color: #000;
        }
      }
    }

    &-separator {
      color: #000;
    }
  }
}
