@import 'src/styles/commons';

.collection-layout {
  margin-left: 200px;
  background-color: #fff;

  .ant-form {
    height: 100%;
  }

  .page-content {
    padding: 24px;
    flex: 1;
    overflow-y: scroll;

    .ant-form-item {
      margin-bottom: 0;
    }

    .input-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 24px;

      .input-label {
        width: 192px;
        margin-right: 16px;
        text-align: right;
      }

      .input-select {
        width: 440px;
      }

      .input-search {
        display: flex;
        flex-grow: 1;

        .ant-form-item {
          width: 100%;
        }
      }
    }
  }
}

.emoji-option {
  margin-left: 5px;
}
