@import 'src/styles/commons';

.extranet-inventory {
  &__property-cell,
  &__date-cell {
    border-bottom: solid 1px #f0f0f0;
    border-left: solid 1px #f0f0f0;
    background-color: #ffffff;
    text-align: center;
    line-height: 32px;

    &--status {
      font-weight: 500;
      font-size: 10px;
    }

    &--inactive {
      background-color: #f9f9f9;
    }

    &--passed {
      color: #8c8c8c;
    }

    background-color: #fff;
    text-align: center;
    line-height: 32px;

    &--inactive {
      background-color: #fafafa;
    }

    &--passed {
      color: #8c8c8c;
    }

    &--end-of-week {
      border-right: solid 1px #000000;
    }

    &--valid {
      background-color: #f4fbf6;
      color: #28a745;
    }

    &--published {
      background-color: #f4fbf6;
      color: #28a745;
    }

    &--rejected {
      background-color: #ffd7d5;
      color: #f5222d;
    }

    &--rejected-light {
      background-color: #e5bcbe;
    }

    &--unavailable {
      background-color: #fffaf2;
      color: #ff9500;
    }

    &--closed_on_staycation {
      background-color: #f9f9f9;
      color: #262626;
    }

    &--closed {
      background-color: #ffd7d5;
      color: #f5222d;
    }

    &--input {
      display: flex;
      justify-content: flex-end;
      font-size: 16px;

      .anticon {
        padding-top: 4px;
        color: $grey500;
      }
    }

    &--alert {
      color: $danger;
      font-weight: 600;
    }

    &--error {
      input {
        margin: 0;
        border: solid 1px $danger;
      }
    }

    &--large {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 64px;
      padding-top: 19px;
      padding-bottom: 19px;
    }

    &--small {
      height: 45px;
    }

    &--without-border {
      border-bottom: none;
    }

    input {
      width: 60%;
      height: 24px;
      text-align: center;
      padding: 0;

      & [type='number'] {
        -moz-appearance: textfield;
      }

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        margin: 0;
        -webkit-appearance: none;
      }
    }

    &-error {
      line-height: 14px;
      color: $danger;
      font-size: 10px;
    }

    .property-cell--with-detail {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 52px;

      &__content {
        margin-top: 12px;
        margin-bottom: 6px;
        line-height: 16px;
        font-size: 14px;

        &.red-text {
          color: #f5222d;
        }
        &.grey-text {
          color: #bfbfbf;
        }
      }

      .anticon {
        margin-top: 8px;
      }

      &__input {
        margin-top: 8px;
        margin-bottom: 2px;
        line-height: 24px;
        font-size: 14px;

        &--focus {
          border-color: #ff5c7f;
          box-shadow: 0 0 0 2px rgba(255, 51, 102, 0.2);
          border-right-width: 1px;
          outline: 0;
        }
      }

      &__detail {
        margin-bottom: 2px;
        line-height: 16px;
        font-size: 12px;
        color: rgb(191, 191, 191);

        &.red-text {
          color: #f5222d;
        }
        &.green-text {
          color: #52c41a;
        }
        &.grey-text {
          color: #bfbfbf;
        }
      }
    }

    .ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner,
    .ant-checkbox-input:focus + .ant-checkbox-inner {
      border-color: rgb(82, 196, 26) !important;
      border-radius: 2px;
    }

    .ant-checkbox-disabled:hover .ant-checkbox-inner {
      background-color: #f5f5f5;
      border-color: #d9d9d9 !important;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: rgb(82, 196, 26) !important;
      border-color: rgb(82, 196, 26) !important;
    }

    .ant-checkbox-checked::after {
      border-color: rgb(82, 196, 26) !important;
      border-radius: 2px;
    }

    .ant-checkbox-inner {
      border-radius: 2px;
    }

    .date-day {
      color: black;
    }
  }

  &__head {
    display: flex;
    flex: 0 0 190px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-left: 0;
    padding: 0 16px;
    text-align: left;
    font-weight: 500;

    .anticon {
      margin-left: 5px;
      color: $grey400;
    }

    &--large {
      height: 64px;
      padding-top: 19px;
      padding-bottom: 19px;
    }

    &--small {
      height: 45px;
    }
  }

  &-tooltip {
    background-color: #fff;
    color: #101010;
    font-size: 12px;
    line-height: 18px;

    &__title {
      color: #101010;
    }

    &__subtitle {
      margin-top: 8px;
      color: #9c9c9c;
    }

    .ant-tooltip-arrow,
    .ant-tooltip-inner {
      background-color: transparent;

      &::before {
        background-color: #fff;
      }
    }

    .ant-tooltip-arrow {
      left: 0px !important;
    }

    &.ant-tooltip.ant-tooltip-placement-topLeft {
      width: 400px;

      .ant-tooltip-content {
        width: 400px;

        .ant-tooltip-inner {
          width: 400px;
          padding: 0;

          .extranet-inventory-tooltip__container {
            width: 400px;
            background: #fff;
            padding: 12px;
          }
        }
      }
    }
  }

  &-status-tooltip {
    background-color: #000000;
    font-size: 12px;
    line-height: 18px;

    &__title {
      color: #ffffff;
      text-align: center;
    }
  }
}
