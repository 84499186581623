@import 'src/styles/commons';

.inventory {
    background-color: #FFF;
    width: 100%;

    .page__container {
        .foo {
            background-color: #FFF;

            .ant-table-thead > tr > th {
                background-color: #F0F0F0;
                padding-top: 0;
                padding-bottom: 0;
                height: 40px;
            }

            .ant-table-thead {
                tr {
                    .ant-table-cell {
                        padding-right: 0;
                        padding-left: 0;
                    }
                }
            }

            .booking__footer {
                display: flex;
                justify-content: flex-end;
                margin-top: 16px;
            }
        }

        .ant-table {
            min-width: 1214px;
        }

        .page__list-header {
            border-bottom: 1px solid #D9D9D9;
            padding-left: 24px;

            .ant-menu {
                .ant-menu-item {
                    margin-right: 24px;
                    padding: 0;
                }
            }
        }

        .ant-tag {
            line-height: 18px;
        }

        .footer {
            display: flex;
            justify-content: flex-end;
            min-width: 106px;
            padding: 16px 0;

            [ant-click-animating-without-extra-node]::after {
                animation: none !important;
                -webkit-animation: none !important;
                -moz-animation: none !important;
                -ms-animation: none !important;
            }

            button {
                margin-left: 5px;
                width: 32px;

                &:first-child {
                    margin-left: 0;
                }

                &:hover,
                &:active,
                &:focus {
                    border-color: #D9D9D9;
                    color: #2D2D2D;
                }
            }
        }
    }

    th.sort-column {
        cursor: pointer;
    }

    .sort-button {
        padding-left: 16px;

        &:hover {
            background-color: inherit;
        }

        &.min-width-90 {
            min-width: 90px;
        }
    }

    .left-right-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .left-right-header__left {
        display: flex;
        flex-grow: 1;
        align-items: center;

        .ant-picker-range {
            width: 350px;
        }

        &-declined {
            margin-right: 12px;
            border-radius: 5px;
            background-color: $danger;
            width: 6px;
            height: 6px;
        }
    }

    .left-right-header__right {
        display: flex;
        align-items: flex-end;
    }

    .left-right-header__search {
        flex-basis: 270px;
        margin-right: 16px;

        .anticon {
            color: #BBB;
        }
    }

    .left-right-header__refresh {
        display: flex;
        align-items: center;
        text-align: right;

        .anticon {
            color: $primary-color;
            font-size: 24px;
            font-weight: 600;
        }
    }

    .left-right-header__last-refresh {
        margin-right: 16px;
        color: $grey500;
        font-size: 12px;
    }

    .inventory__row--disabled {
        color: $grey400;
    }

    .inventory__cell-warn {
        color: $danger;
        font-weight: 600;
    }

    .inventory__room-name {
        margin-left: -35px;

        .ant-badge {
            margin-right: 25px;
        }
    }

    .booking__icon {
        display: flex;
        align-items: center;
        margin-left: 10px;
    }
}
