.input-number__suffix {
  margin-left: 10px;
}

// hide input number's arrow because it reduces the visibility of the input
input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;
}
input[type='number']:hover,
input[type='number']:focus {
  -moz-appearance: textfield;
  appearance: textfield;
}
