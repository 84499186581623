@import 'src/styles/commons';

.automatedDiscountModal {
  max-height: calc(100% - 80px);
  overflow-y: auto;

  .divider {
    color: #f0f0f0;
  }

  .no-margin-divider {
    color: #f0f0f0;
    margin: 0;
  }

  .ant-modal-body {
    padding: 0;
  }

  .automatedDiscountModalTitle {
    display: flex;
    flex-direction: column;
    gap: 4px;

    &_subtitle {
      color: #8c8c8c;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
    }
  }

  &__modal_wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 24px;

    &__discount_form {
      .title {
        font-size: 14px;
        font-weight: 600;
        line-height: 22px; /* 157.143% */
      }
      .ant-form-item {
        margin: 16px 0 8px;

        .ant-form-item-row {
          .ant-form-item-label {
            font-size: 12px;
            margin-right: 0;
            width: fit-content;
            max-width: 100%;
          }

          label {
            font-size: 12px;
          }

          label:after {
            content: none;
          }

          .ant-form-item-control {
            margin: 0 8px;
          }
        }
      }

      .form-errors {
        font-size: 12px;
        color: #ff2e63;
        margin-bottom: 12px;
      }

      .explanation_discount {
        font-size: 12px;
        color: #8c8c8c;
        font-weight: 400;

        &__percent {
          color: #ff2e63;
          font-weight: 600;
        }

        &__percent.disabled {
          color: #8c8c8c;
          font-weight: 600;
        }
      }
    }
    &__automation_explanation_collapse.ant-collapse {
      margin: 0;
      .explanation_panel {
        margin-top: 0;
        border: 1px solid #f0f0f0;
        > .ant-collapse-header {
          padding: 16px !important;
          display: flex;
          align-items: center;
          justify-content: center;

          .explanation_header {
            display: flex;
            align-items: center;

            .anticon {
              font-size: 24px;
              margin-right: 16px;
            }
            h3 {
              font-size: 14px;
              color: #262626;
              font-style: normal;
              font-weight: 600;
              line-height: 22px;
            }
          }
        }

        .description_list {
          list-style: none;
          padding: 0;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin: 0;
          gap: 4px;

          &__item {
            color: #262626;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;

            &--bold {
              font-weight: 600;
            }
          }
        }
      }
    }

    .divider-text {
      color: #8c8c8c;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      word-wrap: break-word;
    }

    .section {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-bottom: 0;
      border: 1px solid #f0f0f0;
      border-radius: 4px;
      overflow: initial;
      padding: 24px 16px 24px 16px;

      h2 {
        margin-bottom: 50px;
      }

      .section-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .section-title {
        display: flex;
        align-items: left;

        .section-icon {
          height: 100%;
          padding: 5px 5px;
          background: #f5f5f5;
          border-radius: 40px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 10px;
          display: inline-flex;
          margin-right: 10px;
          color: #8c8c8c;
        }
      }

      .section-input {
        display: flex;
        align-items: left;
      }
    }

    .section.disabled {
      background: #fafafa;
      color: #8c8c8c !important;
    }
  }
  .ant-modal-footer {
    padding: 10px 24px;

    .automatedDiscountModalFooter {
      width: 100%;
      display: flex;
      justify-content: space-between;
      &__left {
        display: flex;
        align-items: center;

        &__remove_rule {
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 16px;
          padding: 0;

          .ant-btn {
            padding: 0;
          }
        }
      }
      &__right {
        display: flex;
        align-items: center;
        .ant-form-item {
          margin: 0;
        }
      }
    }
  }
}

.automate-discount-confirmation-popin {
  width: 300px;

  p {
    line-height: 22px;
    font-size: 14px;
  }

  .popin-text {
    display: flex;
    flex-direction: column;
    gap: 4px;
    .bold-text {
      font-weight: 600;
    }
  }

  .popin-cta {
    margin-top: 12px;
    text-align: right;
    .popin-no {
      margin-right: 8px;
      height: 24px;
    }

    .popin-yes {
      height: 24px;
    }
  }
}
