@import 'src/styles/commons';


.sortable-list {
    margin-top: 40px;
    border: 1px solid $grey300;
    border-radius: 4px;
    padding: 0;

    &.sortable-list--single {
        margin-top: 0;
    }

    li {
        list-style: none;
    }

    .sortable-item {
        border-bottom: 1px solid $grey300;
    }
}
