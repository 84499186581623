@import 'src/styles/commons';

.bedsBuilder {
  .ant-collapse {
    margin: 0;
    border: 0;

    .ant-collapse-item {
      margin-top: 0;
      margin-bottom: 24px;
      overflow: hidden;
      border-color: rgba(0, 0, 0, 0.15);

      .ant-collapse-header {
        padding: 16px !important;
        color: #101010;
        font-size: 14px;
        font-weight: 500;

        .bed-header {
          display: flex;
          justify-content: space-between;
          align-items: center;

          span {
            svg {
              color: #bfbfbf;
              width: 16px;
              height: 16px;
            }
          }
        }
      }

      .ant-collapse-content {
        border-color: rgba(0, 0, 0, 0.15);

        .ant-collapse-content-box {
          padding: 24px;
        }
      }
    }
  }

  .input-row.price {
    margin-left: 205px;
  }

  .footer {
    display: flex;
    justify-content: flex-end;

    button {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }
  }
}

.bedType {
  display: flex;
  align-items: center;
  gap: 80px;
  padding-left: 26px;
}

.bedCapacity,
.bedCost,
.bedPrice {
  display: flex;
  align-items: center;
  gap: 30px;
  padding-left: 26px;
}

.bedTypeTitle,
.bedCapacityTitle {
  color: #1a1a1a;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.bedCapacityTitle,
.bedCostTitle,
.bedPriceTitle {
  width: 84px;
}

.bedTypeForm,
.bedTypeForm.ant-select {
  display: flex;
  width: 331px;
  align-items: center;
  gap: 4px;
}

.bedCapacityDescription {
  display: flex;
  align-items: center;
  gap: 8px;
}

.bedTypeForm .ant-form-item,
.bedCostForm .ant-form-item,
.bedPriceForm .ant-form-item {
  margin-bottom: 0;
}
