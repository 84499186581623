@import 'src/styles/commons';


.ant-collapse {
    margin: 20px 0;
    background-color: transparent;
    padding: 0;
}

.ant-collapse-item {
    margin-top: 20px;
    border: 1px solid $grey300;
    border-radius: 4px !important;
    background-color: #FFF;

    .ant-collapse-header {
        padding: 18px 50px !important;

        .arrow {
            margin-left: 10px;
            line-height: 0 !important;
        }
    }
}
