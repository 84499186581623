@import 'src/styles/commons';

.sheader {
    top: 0;
    z-index: 5;
    background: #FFF;
    color: #FFF;
    border-bottom: #E0E0E0 solid 1px;
    width: 100%;
    padding: 16px 24px;
    position: sticky;

    .ant-menu-horizontal {
        border-bottom: 0px;
    }

    .ant-page-header-heading {
        height: 32px;
        margin: 0px;
    }

    .ant-page-header-heading-extra {
        margin: 0px;

        .ant-btn {
            font-size: 16px;
            font-weight: 500;
        }
    }

    .ant-page-header-content {
        padding-top: 0px;
    }

    &-children {
        padding: 16px 24px 0 24px;
    }
}
